
import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import "./Canvas.scss"
import MapImage from "./map-border-double.jpg"
import { IconAdd, IconRemove, IconFacebookColored, IconTwitterColored, IconShare } from "../Icons"

const Canvas = ({ originUrl, list, lang }) => {
  const [ canvasX, setCanvasX ] = useState(null)
  const [ canvasY, setCanvasY ] = useState(null)
  const [ isShareOpen, setShareOpen ] = useState(false)
  const [ isCanvasReady, setCanvasReady ] = useState(false)
  const setPositionChange = (e) => {
    setCanvasReady(true)
    setCanvasX(e.positionX)
    setCanvasY(e.positionY)
  }

  const getUrlById = (id) => {
    const object = list.filter(x => x.node.frontmatter.id === id && x.node.frontmatter.lang === lang)

    return object[0].node.frontmatter.url
  }

  const share = {
    title: (lang === "fr")? "Orchestre sous la loupe - Orchestre Métropolitain" : "The Orchestra Up Close - Orchestre Métropolitain",
    user: "LeMetropolitain",
    hashtags: (lang === "fr")? "orchestresouslaloupe" : "orchestraupclose"
  }

  return (
    <section className="canvas">
      <TransformWrapper
        onPanningStart={(e) => setPositionChange(e)}
        onPanningStop={(e) => setPositionChange(e)}
        defaultScale={(isMobile)? 1 : 0.6}
        options={{
          limitToWrapper: true,
          limitToBounds: false,
          centerContent: true,
          minScale: (isMobile)? 1 : 0.6
        }}
        wheel={{
          step: 80
        }}>
        {({ positionX, positionY, zoomIn, zoomOut, ...rest }) => (
          <>
            <div className="canvas__tools">
              <button className="canvas__button" onClick={zoomIn}><IconAdd /></button>
              <button className="canvas__button" onClick={zoomOut}><IconRemove /></button>

              <button className="canvas__button" onClick={() => setShareOpen(!isShareOpen)}><IconShare /></button>
                <div className={`canvas__share ${(isShareOpen)? 'canvas__share--open' : ''}`}>
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${originUrl}`} className="canvas__share-link" target="_blank" rel="noopener noreferrer"><IconFacebookColored /></a>
                  <a 
                    href={`https://twitter.com/share?url=https://souslaloupe.orchestremetropolitain.com&via=${share.user}&text=${share.title}`} 
                    className="canvas__share-link" 
                    data-text={share.title} 
                    data-via={share.user}
                    data-related={share.user}
                    data-hashtags={share.hashtags}
                    data-show-count="false">
                      <IconTwitterColored />
                  </a>
                </div>
            </div>

            <TransformComponent>
              <div className={`canvas__container ${(isCanvasReady && (positionX !== canvasX || positionY !== canvasY))? "canvas__container--locked" : ""}`}>
                <Link className="canvas__zone canvas__zone--piano" dataid="piano" to={getUrlById("piano")} />
                <Link className="canvas__zone canvas__zone--xylo" dataid="xylo" to={getUrlById("xylo")} />
                <Link className="canvas__zone canvas__zone--triangle" dataid="triangle" to={getUrlById("triangle")} />
                <Link className="canvas__zone canvas__zone--symbal" dataid="symbal" to={getUrlById("symbal")} />
                <Link className="canvas__zone canvas__zone--drum" dataid="snare" to={getUrlById("snare")} />
                <Link className="canvas__zone canvas__zone--gong" dataid="gong" to={getUrlById("gong")} />
                <Link className="canvas__zone canvas__zone--bass-drum" dataid="bass-drum" to={getUrlById("bass-drum")} />
                <Link className="canvas__zone canvas__zone--castanets" dataid="castanets" to={getUrlById("castanets")} />
                <Link className="canvas__zone canvas__zone--harp" dataid="harp" to={getUrlById("harp")} />
                <Link className="canvas__zone canvas__zone--bells" dataid="bells" to={getUrlById("bells")} />
                <Link className="canvas__zone canvas__zone--cors" dataid="horn" to={getUrlById("horn")} />
                <Link className="canvas__zone canvas__zone--bass" dataid="bass" to={getUrlById("bass")} />
                <Link className="canvas__zone canvas__zone--timpani" dataid="timpani" to={getUrlById("timpani")} />
                <Link className="canvas__zone canvas__zone--trumpets" dataid="trumpets" to={getUrlById("trumpets")} />
                <Link className="canvas__zone canvas__zone--trombones" dataid="trombones" to={getUrlById("trombones")} />
                <Link className="canvas__zone canvas__zone--tuba" dataid="tuba" to={getUrlById("tuba")} />
                <Link className="canvas__zone canvas__zone--clarinet-bass" dataid="clarinet-bass" to={getUrlById("clarinet-bass")} />
                <Link className="canvas__zone canvas__zone--clarinet" dataid="clarinet" to={getUrlById("clarinet")} />
                <Link className="canvas__zone canvas__zone--bassoon" dataid="bassoon" to={getUrlById("bassoon")} />
                <Link className="canvas__zone canvas__zone--contrabassoon" dataid="contrabassoon" to={getUrlById("contrabassoon")} />
                <Link className="canvas__zone canvas__zone--piccolo" dataid="piccolo" to={getUrlById("piccolo")} />
                <Link className="canvas__zone canvas__zone--transverse-flutes" dataid="transverse-flutes" to={getUrlById("transverse-flutes")} />
                <Link className="canvas__zone canvas__zone--oboe" dataid="oboe" to={getUrlById("oboe")} />
                <Link className="canvas__zone canvas__zone--english-horn" dataid="english-horn" to={getUrlById("english-horn")} />
                <Link className="canvas__zone canvas__zone--altos" dataid="alto" to={getUrlById("alto")}>
                  <span className="patch" dataid="alto" />
                </Link>
                <Link className="canvas__zone canvas__zone--cellos" dataid="cello" to={getUrlById("cello")}>
                  <span className="patch" dataid="cello" />
                </Link>
                <Link className="canvas__zone canvas__zone--violin1" dataid="first-violin" to={getUrlById("first-violin")}>
                  <span className="patch" dataid="violin" />
                </Link>
                <Link className="canvas__zone canvas__zone--violin2" dataid="second-violin" to={getUrlById("second-violin")}>
                  <span className="patch" dataid="violin" />
                </Link>
                <Link className="canvas__zone canvas__zone--chef" dataid="chef" to={getUrlById("chef")} />
                
                <img src={MapImage} alt="data map" />
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </section>
  )
}

Canvas.propTypes = {
  originUrl: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired
}

export default Canvas
