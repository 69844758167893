import React from "react"
import { graphql } from "gatsby"
import { isMobileOnly } from "react-device-detect"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Canvas from "../components/Canvas"
import MultiView from "../components/DetailsView/MultiView"

const BoardPage = ({ data, location }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const list = data.allMarkdownRemark.edges

  return (
    <>
      <SEO 
        title={frontmatter.seo.title}
        description={frontmatter.seo.description}
        image={frontmatter.seo.image}
      />
      <Layout>
        <Header 
          lang={frontmatter.lang} />

        {(isMobileOnly)? 
          <MultiView 
            originUrl={location.origin}
            list={list} 
            lang={frontmatter.lang} /> : 
          <Canvas 
            shareTitle={frontmatter.seo.title}
            originUrl={location.href}
            lang={frontmatter.lang}
            list={list} />
        }
      </Layout>
    </>
  )
}

export const canvaQuery = graphql`
  query instrumentListAndBoardPage ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        lang
        seo {
          title
          description
          image {
            publicURL
            relativePath
          }
        }
      }
    }

    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "instrument"} }}) {
      edges {
        node {
          frontmatter {
            id
            url
            lang
            order
            title
            familly
            image {
              childImageSharp {
                fluid(maxWidth: 1024, traceSVG: {color: "#BFAD86"}, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
              publicURL
            }
            media {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            media_legend
            description
            hook
            intro
            music {
              title
              sub
              link
            }
            suggestion
            suggestion_link
          }
        }
      }
    }
  }
`

export default BoardPage
