import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import SingleView from "./SingleView"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./DetailsView.scss"

const MultiView = ({ list, lang, originUrl }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // fade: true,
    centerMode: false,
    centerPadding: '115px',
  }

  const listFilteredByLangAndSortByOrder = list.filter(x => x.node.frontmatter.lang === lang).sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
  const listWithoutSecondViolin = listFilteredByLangAndSortByOrder.filter(x => x.node.frontmatter.id !== "second-violin")

  return (
    <article className="detailsview detailsview--list">
      <Slider {...sliderSettings}>
        {listWithoutSecondViolin.map(x => (
          <div key={x.node.frontmatter.id}>
            <SingleView originUrl={originUrl} instrument={x.node.frontmatter} lang={lang} />
          </div>
        ))}
      </Slider>
    </article>
  )
}

MultiView.propTypes = {
  list: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired
}

export default MultiView
